<template>
<div class="d-flex flex-column-fluid pt-0">
<div class="container action-page-container">
    <div class="card card-custom">
        <div class="card-body">
            <h2 class="content-title">Upload File</h2>
            <div class="row align-items-center h-100">
            <div class="col-12">
                <div class="action-pages">
                    <div class="action-inner-content">
                        <form>
                            <div class="form-group">
                                <label for="exampleFormControlFile1">Upload CSV File Only</label>
                                <input type="file" class="form-control-file" id="exampleFormControlFile1" accept=".csv">
                            </div>
                            
                            <button type="submit" class="action-submit btn btn-primary">Upload File</button>
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        
    },
})
</script>
